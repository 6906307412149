import React, { useState } from "react";
import "./features/css/listfile.css";
import { IconButton, Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Listbody({
  testcases,
  ind,
  addtoar,
  removetoar,
  product,
  moduleid,
  dragon,
  dropon,
  delButton,
  clickonSuites,
}) {
  let name = "";
  let nametool = "";
  let testcase;
  testcase = testcases;
  if (testcases?.hasOwnProperty("Test_Case")) {
    name = testcase["Test_Case"];
    if (testcase.Test_Case_Desc === "" || testcase.Test_Case_Desc === null) {
      nametool = testcase["Test_Case"];
    } else {
      nametool = `${testcase["Test_Case"]}-${testcase.Test_Case_Desc}`;
    }
  } else if (testcases?.hasOwnProperty("testcases")) {
    testcase = testcases["testcases"]["0"];
    name = testcase["Test_Case"];
    nametool = testcase["Test_Case"];
  } else if (testcase[0]?.hasOwnProperty("Test_Suite")) {
    name = testcase[0]["Test_Suite"];
    nametool = testcase[0]["Test_Suite"];
    testcase = testcase[0];
  }

  const [select, setselect] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setselect(true);
  }, [testcase]);

  function selected() {
    setselect((prevselect) => !prevselect);
    if (select) addtoar(testcase, testcases.TestcaseIndex);
    if (!select) removetoar(testcase, testcases.TestcaseIndex);
  }

  function drag() {
    if (delButton) dragon(ind, testcases.TestcaseIndex, testcases.testcases[0].idtest_case);
  }

  function drop() {
    if (delButton) dropon(ind, testcases.TestcaseIndex);
  }

  const handleTestCaseClick = () => {
    if (testcase?.hasOwnProperty("Test_Suite")) {
      clickonSuites(testcase["idtest_suite"]);
    } else {
      // Create the state object for the new route
      const navigationState = {
        fromRecordingPage: true,
        idproducts: product,
        idmodules: moduleid,
        idtest_case: testcase["idtest_case"],
        Test_Case: testcase["idtest_case"],
        details: testcase,
      };

      // Create the full URL for the new tab
      const baseUrl = window.location.origin;
      const path = `/dashboard/recording`;
      const stateParam = encodeURIComponent(JSON.stringify(navigationState));
      const url = `${baseUrl}${path}?state=${stateParam}`;

      // Open in new tab
      window.open(url, "_blank");
    }
  };

  return (
    <div
      className="listbody"
      draggable={true}
      id={ind}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={drag}
      onDrop={drop}
    >
      <Tooltip title={<h2>{nametool}</h2>} arrow>
        <div className="sp">
          <div hidden={!select} className="listbody-left">
            <IconButton onClick={selected}>
              <CheckBoxOutlineBlankIcon />
            </IconButton>
          </div>
          <div hidden={select} className="listbody-left">
            <IconButton onClick={selected}>
              <CheckBoxIcon />
            </IconButton>
          </div>
          <div onClick={handleTestCaseClick} className="listbody-right">
            <p>{name}</p>
          </div>
          <div className="save">
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default Listbody;
