import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import { InfoIcon } from "../../components-new/icons";
import { useUpdateRankingMutation } from "../../useQuery";

const initialItems = [
  { id: "id", content: "id" },
  { id: "datasetid", content: "dataset-id" },
  { id: "placeholder", content: "placeholder" },
  { id: "classname", content: "classname" },
  { id: "text", content: "text" },
  { id: "xpath", content: "xpath" },
];

const RankingPage = () => {
  const [items, setItems] = useState(initialItems);
  const [ranking, setRanking] = useState(
    initialItems.map((item, index) => ({
      rank: index + 1,
      item: item.content,
    })),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateRankingMutation = useUpdateRankingMutation();

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, reorderedItem);

    setItems(newItems);

    const newRanking = newItems.map((item, index) => ({
      rank: index + 1,
      item: item.content,
    }));
    setRanking(newRanking);
  };

  const handleSubmit = async () => {
    setSubmitStatus(null);
    setIsSubmitting(true);

    try {
      const rankingPayload = ranking.map((item) => ({
        rank: item.rank,
        option: item.item,
      }));
      await updateRankingMutation.mutateAsync({ data: rankingPayload });
      setSubmitStatus("success");
    } catch (error) {
      console.error("Error submitting ranking:", error);
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="bg-gradient-to-t from-[#6E38E0] to-[#FF5F36] 
 min-h-screen  ml-[320px]   flex items-center justify-center flex-col "
    >
      {/* //white overlay over the background */}

      <div className="flex flex-row gap-2 items-center">
        <h1
          className="text-white font-bold text-3xl urbanist-font shadow-3xl"
          style={{
            textShadow: "text-shadow: 2px 9px 10px #000000",
          }}
        >
          Rank Your Options
        </h1>
        <button onClick={() => setIsModalOpen(true)} className="ml-2 text-white text-xl z-50">
          {/* //info icon with white color */}
          <InfoIcon color="white" className="h-4 w-4" />
        </button>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="ranking-list">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex flex-col mt-6 max-w-[496px] w-full z-50"
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="bg-white py-4 mb-3 rounded  flex items-center justify-center shadow-custom font-bold urbanist-font text-[15px] leading-5 text-[#1E1E1E]"
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mt-4 w-full max-w-[333px] z-50">
        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={`w-full py-4 rounded-[10px] shadow-2xl ${
            isSubmitting
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-black  text-white font-bold text-[15px] leading-5 urbanist-font"
          }`}
        >
          {isSubmitting ? "Submitting..." : "Submit Ranking"}
        </button>

        {submitStatus === "success" && (
          <div className="mt-2 text-green-600 text-center urbanist-font">
            Ranking submitted successfully!
          </div>
        )}
        {submitStatus === "error" && (
          <div className="mt-2 text-red-600 text-center urbanist-font">
            Failed to submit ranking. Please try again.
          </div>
        )}
      </div>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="element-locators-description"
      >
        <DialogTitle id="element-locators-description">Element Locators Description</DialogTitle>
        <DialogContent>
          <p className="text-lg font-semibold text-gray-800 mb-4">
            The following is the default sequence of element locators, which can be adjusted based
            on your preferences:
          </p>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>
              <span className="font-bold">id:</span> Use the{" "}
              <code className="bg-gray-200 px-1 rounded">id</code>
              attribute to locate elements.
            </li>
            <li>
              <span className="font-bold">dataset-id:</span> Preferred if your development team
              provides consistent <code className="bg-gray-200 px-1 rounded">data-test-id</code>{" "}
              attributes for all elements.
            </li>
            <li>
              <span className="font-bold">placeholder:</span> Effective for locating input fields
              using the <code className="bg-gray-200 px-1 rounded">placeholder</code> attribute.
            </li>
            <li>
              <span className="font-bold">classname:</span> Locate elements using the
              <code className="bg-gray-200 px-1 rounded">class</code> attribute.
            </li>
            <li>
              <span className="font-bold">text:</span> Use visible text to identify elements.
            </li>
            <li>
              <span className="font-bold">xpath:</span> A fallback option if other locators are
              unavailable or insufficient.
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RankingPage;
