import { Box, Switch, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { StatusOptions, WaitOptions } from "../constants";
import { CustomInput, CustomSelect } from "../../../components-new/common";
import { setEditStep } from "../../../Redux/TestAuthoring/TestAuthoring";

const Elements = ({ item }) => {
  const dispatch = useDispatch();

  const handleChange = (value, field) => {
    dispatch(setEditStep({ ...item, [field]: value }));
  };

  return (
    <Box pr={3}>
      {/* <Box bgcolor="#E2E6F3" p="20px" borderRadius="13px" mb={3}>

      </Box> */}
      <CustomInput
        onChange={(e) => handleChange(e.target.value, "Page_Description")}
        placeholder="Enter page description"
        labelinput="Page description"
        value={item.Page_Description || ""}
      />
      <Box display="flex" my={3} columnGap={2}>
        <CustomSelect
          label={"Status"}
          options={StatusOptions}
          width={"100%"}
          background={"#ffffff"}
          value={item.Flow}
          onChange={(e) => handleChange(e.target.value, "Flow")}
        />
        <CustomInput
          onChange={(e) => handleChange(e.target.value, "teststepthreshold")}
          placeholder="Enter step threshold"
          labelinput="Step threshold"
          value={item.teststepthreshold || ""}
          flex={1}
        />
        <CustomSelect
          label={"Wait"}
          options={WaitOptions}
          width={"100%"}
          background={"#ffffff"}
          value={item.wait}
          onChange={(e) => handleChange(e.target.value, "wait")}
        />
        <CustomInput
          onChange={(e) => handleChange(e.target.value, "waittime")}
          placeholder="Enter wait time"
          labelinput="Wait time"
          value={item.waittime || ""}
          flex={1}
        />
      </Box>
      <Box display={"flex"} columnGap={2}>
        <CustomInput
          onChange={(e) => handleChange(e.target.value, "type")}
          placeholder="Enter type"
          labelinput="Type"
          value={item.type || ""}
          flex={1}
        />
        <CustomInput
          onChange={(e) => handleChange(e.target.value, "inlinedepedence")}
          placeholder="Enter Inline Test Case Id"
          labelinput="Inline"
          value={item.inlinedepedence || ""}
          flex={1}
        />
        <CustomInput
          onChange={(e) => handleChange(e.target.value, "color")}
          placeholder="Enter color"
          labelinput="Color"
          value={item.color || ""}
          flex={1}
        />
        <CustomInput
          onChange={(e) => handleChange(e.target.value, "bgcolor")}
          placeholder="Enter background color"
          labelinput="Background color"
          value={item.bgcolor || ""}
          flex={1}
        />
      </Box>
      <Box display={"flex"} columnGap={2} mt={3}>
        <Box>
          <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
            Dynamic Value
          </Typography>
          <Switch
            checked={!!item.isDynamic}
            onChange={(e) => handleChange(e.target.checked ? 1 : 0, "isDynamic")}
          />
        </Box>

        <Box>
          <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
            Create Alert
          </Typography>
          <Switch
            checked={!!item.createsAlert}
            onChange={(e) => handleChange(e.target.checked ? 1 : 0, "createsAlert")}
          />
        </Box>
        <CustomInput
          onChange={(e) => handleChange(e.target.value, "imgpath")}
          placeholder="Enter image path"
          labelinput="Image Path"
          value={item.imgpath || ""}
          flex={1}
        />
      </Box>
    </Box>
  );
};

export default Elements;
