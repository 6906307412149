import React from "react";

const ReplaceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 25 22" fill="none">
    <path
      d="M18.265 21.93a.1.1 0 0 1-.14 0l-3.783-3.741a.1.1 0 0 1 0-.142l3.798-3.758a.1.1 0 0 1 .142 0l.774.781a.1.1 0 0 1 0 .142l-2.11 2.087a.1.1 0 0 0 .07.172h5.657a.1.1 0 0 0 .1-.1V11.1a.1.1 0 0 1 .1-.1h1.108a.1.1 0 0 1 .1.1v7.565a.1.1 0 0 1-.1.1h-6.964a.1.1 0 0 0-.07.171l2.108 2.07a.1.1 0 0 1 0 .143zM5.816.07a.1.1 0 0 1 .14 0L9.74 3.81a.1.1 0 0 1 0 .142l-3.8 3.759a.1.1 0 0 1-.141 0l-.775-.781a.1.1 0 0 1 0-.142L7.135 4.7a.1.1 0 0 0-.07-.17H1.409a.1.1 0 0 0-.1.1v6.27a.1.1 0 0 1-.1.1H.1a.1.1 0 0 1-.1-.1V3.335a.1.1 0 0 1 .1-.1h6.964a.1.1 0 0 0 .07-.171L5.027.994a.1.1 0 0 1 0-.143z"
      fill="#2B3539"
    />
    <rect y="12.783" width="12.486" height="9.216" rx="1" fill="#2B3539" />
    <rect x="11.595" width="12.486" height="9.216" rx="1" fill="#2B3539" />
  </svg>
);

export default ReplaceIcon;
