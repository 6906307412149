import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";
import axios from "axios";

const copyTC = async ({ tcid, copyNewName }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=copyTC&companyid=${user.companyid}&token=${user.token}&tcid=${tcid}&tcname=${copyNewName}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
      throw error;
    });

  return response;
};

const useCopyTCQuery = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: copyTC,
    onSuccess: () => {
      queryClient.invalidateQueries(["testCaseResults", "searchTCSearch"]);
    },
    onError: (error) => {
      console.log("Error uploading file", error.message);
    },
  });

  //Reset functionality
  const mutateAndReset = async (...args) => {
    try {
      await mutation.mutateAsync(...args);
    } finally {
      // Reset after a short delay to ensure the success state is processed
      setTimeout(() => {
        mutation.reset();
      }, 100);
    }
  };

  return {
    ...mutation,
    mutate: mutateAndReset,
  };
};

export default useCopyTCQuery;
